<template lang="pug">
AppPaymentForm(:service="service" :invoice="invoice" :cards="cards" :loading="loading" @pay="submitForm" :hasError="hasError")
</template>

<script>
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useGtag } from "vue-gtag-next";

import { axios } from "@/utils/axios";
import { store } from "@/store";

import AppPaymentForm from "@/components/Payment/AppPaymentForm";
import AppHelpText from "@/components/AppHelpText";

export default {
  components: { AppPaymentForm, AppHelpText },
  setup() {
    const { event } = useGtag();
    const route = useRoute();
    const router = useRouter();

    const loading = ref(false);
    const service = ref(null);
    const invoice = ref(null);
    const cards = ref(null);
    const hasError = ref(false);

    const submitForm = async (card) => {
      try {
        if (loading.value) {
          return;
        }
        hasError.value = false;
        loading.value = true;
        const invoiceValue = invoice.value.amount_residual.toFixed(2);

        const formData = {
          ...card,
          amount: Math.ceil(invoiceValue * 100),
          currency: "mxn",
          reference: service.value.no_contrato,
          description: invoice.value.id,
        };

        let form_data = new FormData();

        for (var key in formData) {
          form_data.append(key, formData[key]);
        }

        if (formData.id_pm) {
          await axios.post("payment_triggers/customer", form_data);
        } else {
          await axios.post("payment_triggers/", form_data);
        }

        event("pay", {
          event_category: `${formData.id_pm ? "existend" : "new"}_card`,
          event_label: "success",
          value: invoiceValue,
        });
        router.push({ name: "InvoicesView", id: invoice.value.id });
      } catch (error) {
        event("pay", {
          event_category: "payment",
          event_label: "error",
        });
        console.log(error);
        hasError.value = true;
      } finally {
        loading.value = false;
      }
    };

    onMounted(async () => {
      try {
        store.APP_LOADING = true;
        loading.value = true;

        const invoiceRoute = `invoicesodoo/${route.params.id}`;
        const { data: invoiceData } = await axios.get(invoiceRoute);
        invoice.value = invoiceData.payload[0];

        const serviceRoute = `service/${invoice.value.servicio_id[1]}`;
        const { data: serviceData } = await axios.get(serviceRoute);
        service.value = serviceData.payload[0];

        const { data: cardsData } = await axios.get(`cards/`);
        cards.value = cardsData.payload;
      } catch (error) {
        console.log(error);
      } finally {
        loading.value = false;
        store.APP_LOADING = false;
      }
    });

    return { loading, service, invoice, cards, hasError, submitForm };
  },
};
</script>
